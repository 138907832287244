<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center" class="fill-height">
      <v-col cols="12" class="text-center">
        <v-progress-circular
          :width="7"
          :size="70"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
// Reserve.vue の <script setup lang="ts"> 部分

import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useReservationStore } from '@/stores/reservationStore'

const router = useRouter()
const route = useRoute()
const reservationStore = useReservationStore()

onMounted(async () => {
  // session_id または stores がクエリに含まれている場合
  if (route.query.session_id || route.query.stores) {

    if (route.query.session_id) {
      reservationStore.reserveData.paymentId = route.query.session_id as string
    } else if (route.query.stores) {
      reservationStore.reserveData.paymentId = reservationStore.settingData.stores?.id
    }
  }
    // ローカルストレージから予約データを読み込む
    await reservationStore.loadFromLocalStorage()
    console.log('stripe redirect reserveData', reservationStore.reserveData)
    
    // クエリパラメータに応じて paymentId を設定
    
    
    const reserveData = reservationStore.reserveData
    // ① 正式予約のコミット処理
    const commitSuccess = await reservationStore.commit(reserveData.id)
    if (!commitSuccess) {
      alert('正式予約に失敗しました')
      return
    }
    
    // ② Zoom ミーティング作成（設定で有効かつ paymentOnly でない場合）
    let zoomResult = null
    if (reservationStore.settingData.zoom && !reserveData.paymentOnly) {
      try {
        zoomResult = await reservationStore.createZoomMeeting(reserveData, reservationStore.reserveUser)
      } catch (e) {
        alert('Zoomミーティングの作成に失敗しました')
        await reservationStore.cancel(reserveData.id)
        router.back()
        return
      }
      console.log('zoomResult:', zoomResult)
    }
    console.log('reservationStore.reserveUser',reservationStore.reserveUser)
    const plans = JSON.parse(localStorage.getItem('plans') || '[]')
    const reserveUserData = {
      ...reservationStore.reserveUser,
      selectedPlan: plans.find((plan: any) => plan.id === reservationStore.reserveUser.selectedPlan.id),
    };

    
    // ③ 予約正式登録、完了処理、メール送信
    console.log('reserveData:', reserveData)
    console.log('settingData:', reservationStore.settingData)
    console.log('reserveUser:', reserveUserData)
    await reservationStore.addReserve(zoomResult, reserveData,reservationStore.settingData,reserveUserData)
    await reservationStore.reserveComplete(zoomResult,reserveData, reservationStore.settingData, reserveUserData)
    await reservationStore.sendEmail(zoomResult, reserveData, reservationStore.settingData, reserveUserData)
    
    // ④ 予約完了画面へ遷移
    router.push(`/${route.params.uid}/ReserveComplete`)
  })

</script>
