// src/stores/reservedPageStore.ts
import { defineStore } from 'pinia';
import { firestoreService } from '@/services/firebaseService';

export const useReservedPageStore = defineStore('reservedPage', {
  state: () => ({
    serverItems: [] as any[],
    plans: [] as any[],
    reserveHeaders: [] as any[],
    paymentOnlyHeaders: [] as any[],
    sortBy: '',
    show: false,
    dialog: false,
    dialogDelete: false,
    dialogPaymentOnly: false,
    dialogPaymentOnlyDelete: false,
    editedItem: {} as any,
    // 以下、各種ヘルパー状態
    tooltip1: false,
    tooltip2: false,
    tooltip3: false,
  }),
  actions: {
    async loadReservations(uid: string) {
      try {
        const reservations = await firestoreService.fetchReserveDays(uid);
        const plans = await firestoreService.fetchPlans(uid);
        this.serverItems = reservations;
        this.plans = plans;
        console.log('reservations:', reservations);
      } catch (error) {
        console.error('Error loading reservations:', error);
      }
    },
    toggleHelp() {
      this.show = !this.show;
    },
    togglePaymentOnlyHelp() {
      this.dialogPaymentOnly = !this.dialogPaymentOnly;
    },
    close() {
      this.dialog = false;
      this.dialogPaymentOnly = false;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.dialogPaymentOnlyDelete = false;
    },
    editItem(item: any) {
      // 編集用アイテムにコピーしてダイアログを開く
      this.editedItem = { ...item };
      this.dialog = true;
    },
    deleteItem(item: any) {
      // 削除確認ダイアログを開く
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      // 実際の削除処理を実装する
      // 例: Firebase 削除処理後、serverItems から削除
      this.serverItems = this.serverItems.filter(
        (item: any) => item.id !== this.editedItem.id
      );
      this.closeDelete();
    },
    deleteItemConfirmPaymentOnly() {
      // 支払いのみの削除処理
      this.serverItems = this.serverItems.filter(
        (item: any) => item.id !== this.editedItem.id
      );
      this.closeDelete();
    },
    save() {
      // 編集ダイアログでの保存処理を実装
      // 例: Firebase 更新処理など
      // ここでは、serverItems 内の対象アイテムを更新する例
      this.serverItems = this.serverItems.map((item: any) => {
        if (item.id === this.editedItem.id) {
          return { ...this.editedItem };
        }
        return item;
      });
      this.close();
    },
    // copyUrl は dashboardCalendarStore の機能と共通するため、こちらに移すか dashboardCalendarStore に委譲する
    copyUrl(tooltip: number, url: string) {
      navigator.clipboard.writeText(url);
      if (tooltip === 1) {
        this.tooltip1 = true;
        setTimeout(() => {
          this.tooltip1 = false;
        }, 3000);
      } else if (tooltip === 2) {
        this.tooltip2 = true;
        setTimeout(() => {
          this.tooltip2 = false;
        }, 3000);
      } else if (tooltip === 3) {
        this.tooltip3 = true;
        setTimeout(() => {
          this.tooltip3 = false;
        }, 3000);
      }
    },
  },
});
