<script setup lang="ts">
import { onMounted, onUnmounted, ref, computed } from 'vue'
import FullCalendar from '@fullcalendar/vue3'
import { useDashboardCalendarStore } from '@/stores/dashboardCalendarStore'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'

const router = useRouter()
const dashboardCalendar = useDashboardCalendarStore()
const menuRef = ref<HTMLElement | null>(null)

// 「変更する」ボタンの disable フラグ
const disableEditButton = computed(() => {
  // 選択されたイベントの中に googleCalendar が true のものがあれば disable する
  return dashboardCalendar.selectedEventDetails.some(item => item.googleCalendar)
})

// computed プロパティで FullCalendar のオプションを構築
const calendarOptions = computed(() => ({
  initialView: 'dayGridMonth',
  locale: dashboardCalendar.locale,
  plugins: dashboardCalendar.plugins,
  events: dashboardCalendar.events,
  editable: true, // ドラッグ＆ドロップが有効になります
  eventClick: dashboardCalendar.eventClick,
  dateClick: dashboardCalendar.dateClick,
  eventDidMount: dashboardCalendar.eventDidMount,
  dayCellDidMount: dashboardCalendar.dayCellDidMount,
  eventContent: (arg: any) => {
    console.log(arg);
    const timeText = arg.timeText;
    const selectedPlanId = arg.event.extendedProps.selectedPlan;
    const plans = dashboardCalendar.plans;
    const planObj = plans.find((p: any) => p.id === selectedPlanId);
    const planName = planObj ? planObj.title : '';
    

    // イベント全体の色として、もし設定されていれば backgroundColor または extendedProps.color を使用
    const color = arg.event.backgroundColor || arg.event.extendedProps.color || '#3788d8';

    // 小さな丸いカラーマーカーを作成
    const markerHtml = `<span style="
      background-color: ${color};
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 4px;
    "></span>`;

    const trimmedPlanName = planName.length > 8 ? planName.slice(0, 8) + '...' : planName;
    const html = `<div>${markerHtml}<strong>${timeText}</strong> ${trimmedPlanName}</div>`;
    return { html };
  },
  // その他必要なオプション
}));

const handleClickOutside = (event: any) => {
  if (menuRef.value && !menuRef.value.contains(event.target)) {
    dashboardCalendar.menu = false
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})

const saveEventEdit = () => {
  dashboardCalendar.saveEventEdit()
}

const saveEventNew = () => {
  dashboardCalendar.saveEventNew()
}

const menuAction = (action: string, date: any) => {
  dashboardCalendar.menuAction(action, date)
}

const getGoogleCalendarEvents = () => {
  dashboardCalendar.getGoogleCalendarEvents()
}
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn 
          @click="getGoogleCalendarEvents"  
          :disabled="dashboardCalendar.googleCalendarEventsFetched">
          Google カレンダーから取得
        </v-btn>
        <!-- FullCalendar に computed の options を渡す -->
        <full-calendar :options="calendarOptions"></full-calendar>
  
        <!-- 編集用ダイアログ -->
        <v-dialog v-model="dashboardCalendar.showModal" max-width="500px">
          <v-card lg="6">
            <div v-for="(item, index) in dashboardCalendar.selectedEventDetails" :key="item.id">
              <v-card-title>{{ item.startStr }} {{ item.title }}</v-card-title>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field v-model="item.month" maxlength="2" suffix="月" :readonly="disableEditButton"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model="item.day" maxlength="2" suffix="日" :readonly="disableEditButton"></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- 開始時間: 時間と分を横並び -->
                  <v-row>
                    <v-col cols="6">
                      <v-text-field 
                      :readonly="disableEditButton"
                        type="number" 
                        v-model="item.startHour" 
                        label="開始時間" 
                        maxlength="2" 
                        suffix="時" 
                        required />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field 
                      :readonly="disableEditButton"
                        type="number" 
                        v-model="item.startMinute" 
                        label="開始分" 
                        maxlength="2" 
                        suffix="分" 
                        required />
                    </v-col>
                  </v-row>
                  <!-- 終了時間: 時間と分を横並び -->
                  <v-row>
                    <v-col cols="6">
                      <v-text-field 
                      :readonly="disableEditButton"
                        type="number" 
                        v-model="item.endHour" 
                        label="終了時間" 
                        maxlength="2" 
                        suffix="時" 
                        required />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field 
                      :readonly="disableEditButton"
                        type="number" 
                        v-model="item.endMinute" 
                        label="終了分" 
                        maxlength="2" 
                        suffix="分" 
                        required />
                    </v-col>
                  </v-row>
                </v-form>
                <!-- radio 部分: googleCalendar が true の場合は非表示 -->
                <v-radio-group 
                  v-if="!item.googleCalendar" 
                  v-model="item.selectedPlan">
                  <div v-for="plan in item.plan" :key="plan.id">
                    <v-radio 
                      :label="`${plan.title} - ${plan.price}円`" 
                      :value="plan.id"></v-radio>
                  </div>
                </v-radio-group>
              </v-card-text>
            </div>
            <v-card-actions>
              <v-btn color="primary" @click="dashboardCalendar.showModal = false">キャンセル</v-btn>
              <!-- disable 属性は computed の disableEditButton を利用 -->
              <v-btn @click="saveEventEdit()" :disabled="disableEditButton">
                変更する
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  
        <!-- 新規追加用ダイアログ -->
        <v-dialog v-model="dashboardCalendar.addReserveModal" max-width="500px">
          <v-card lg="6">
            <v-card-title>新規追加</v-card-title>
            <v-card-text v-for="item in dashboardCalendar.selectedEventDetails" :key="item.id">
              <v-form>
                <v-row>
                    <v-col cols="6">
                      <v-text-field v-model="item.month" maxlength="2" suffix="月" :readonly="disableEditButton"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model="item.day" maxlength="2" suffix="日" :readonly="disableEditButton"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                  <v-col cols="6">
                    <v-text-field 
                      :readonly="disableEditButton"
                      v-model="item.startHour" 
                      label="開始時間" 
                      maxlength="2" 
                      suffix="時" 
                      required />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field 
                      :readonly="disableEditButton"
                      v-model="item.startMinute" 
                      label="開始分" 
                      maxlength="2" 
                      suffix="分" 
                      required />
                  </v-col>
                </v-row>
                <!-- 終了時間: 時間と分を横並び -->
                <v-row>
                  <v-col cols="6">
                    <v-text-field 
                      :readonly="disableEditButton"
                      v-model="item.endHour" 
                      label="終了時間" 
                      maxlength="2" 
                      suffix="時" 
                      required />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field 
                      :readonly="disableEditButton"
                      v-model="item.endMinute" 
                      label="終了分" 
                      maxlength="2" 
                      suffix="分" 
                      required />
                  </v-col>
                </v-row>
                <!-- 入力値が全て埋まった時のみバリデーションを実施し、エラーがあれば1ヶ所に表示 -->
                <v-alert 
                  v-if="dashboardCalendar.validateTimeOverlap(item) !== true && item.startHour !== '' && item.startMinute !== '' && item.endHour !== '' && item.endMinute !== ''"
                  type="error" dense text>
                  {{ dashboardCalendar.validateTimeOverlap(item) }}
                </v-alert>

                <v-radio-group v-model="item.selectedPlan" required>
                  <div v-for="plan in item.plan" :key="plan.id">
                    <v-radio :label="`${plan.title} - ${plan.price}円`" :value="plan.id"></v-radio>
                  </div>
                </v-radio-group>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn 
                color="primary" 
                @click="dashboardCalendar.addReserveModal = false" 
                :disabled="dashboardCalendar.adding">
                キャンセル
              </v-btn>
              <v-btn 
                @click="saveEventNew()" 
                :disabled="dashboardCalendar.adding">
                追加する
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  
        <div
          v-if="dashboardCalendar.menu"
          class="context-menu"
          :style="{ top: dashboardCalendar.menuY + 'px', left: dashboardCalendar.menuX + 'px' }"
          ref="menuRef"
        >
          <v-list>
            <v-list-item
              v-for="(item, index) in dashboardCalendar.menuItems"
              :key="index"
              @click="menuAction(item.action, item.date)"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.context-menu {
  position: absolute;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.v-list-item {
  cursor: pointer;
}

.v-list-item:hover {
  background-color: #f0f0f0;
}
</style>
