// src/services/firebaseService.ts

import { initializeApp } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from 'firebase/auth'
import { getFirestore, doc, getDoc, setDoc, collection, query, where, getDocs, deleteDoc, serverTimestamp } from 'firebase/firestore'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import dayjs from 'dayjs'
declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN?: boolean | string;
  }
}

// ローカル環境の場合、デバッグトークンを設定（"true" を設定すると Firebase が自動的にデバッグモードとして扱います）
if (typeof self !== 'undefined' && (process.env.NODE_ENV === 'development' || window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')) {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}


// --- Firebase 初期化 ---
const firebaseConfig = {
  apiKey: "AIzaSyDk93wPbVFCK7Srjhc1dGM6ZS0NWCKjvmw",
  authDomain: "homepagelp.firebaseapp.com",
  projectId: "homepagelp",
  storageBucket: "homepagelp.appspot.com",
  messagingSenderId: "494662465260",
  appId: "1:494662465260:web:777d8d2c4fc9d8f22717c9"
}



export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

// 通常は ReCaptchaV3Provider を使いますが、デバッグモードの場合は localStorage に設定したトークンが使われます
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LcRspgcAAAAANqqpiNxqJzwaCYavrzOMsCxpC8c'),
  isTokenAutoRefreshEnabled: true,
});

// --- Collections ---
const reserveDays = collection(db, 'reserveDaysV2')
const plans = collection(db, 'plansV2')
const customers = collection(db, 'customerV2')
const orders = collection(db, 'ordersV2')
const customerSecrets = collection(db, 'customerSecretV2')

// --- Auth APIs ---
export const authService = {
  login: (email: string, password: string) => signInWithEmailAndPassword(auth, email, password),
  logout: () => signOut(auth),
  register: (email: string, password: string) => createUserWithEmailAndPassword(auth, email, password),
  resetPassword: (email: string) => sendPasswordResetEmail(auth, email),
}

// --- Firestore APIs ---
export const firestoreService = {
  getUserData: async (uid: string) => {
    const snap = await getDoc(doc(customers, uid))
    return snap.exists() ? snap.data() : null
  },

  saveUserSettings: (uid: string, data: object) => setDoc(doc(customers, uid), data, { merge: true }),
  saveSecretSettings: (uid: string, data: object) => setDoc(doc(customerSecrets, uid), data, { merge: true }),

  fetchPlans: async (uid: string) => {
    const q = query(plans, where('uid', '==', uid))
    const snaps = await getDocs(q)
    return snaps.docs.map(d => ({ id: d.id, ...d.data() }))
  },

  savePlan: (plan: any) => setDoc(doc(plans, plan.id), plan, { merge: true }),

  fetchReserveDays: async (uid: string) => {
    const today = dayjs().toDate();
    const q = query(reserveDays, where('uid', '==', uid), where('start', '>=', today));
    const snaps = await getDocs(q);
    return snaps.docs.map(d => {
      const data = d.data();
      return {
        id: d.id,
        ...data,
        start: data.start?.toDate ? data.start.toDate() : data.start,
        end: data.end?.toDate ? data.end.toDate() : data.end,
      }
    });
  },

  tempReserve: (id: string, email: string) => setDoc(doc(reserveDays, id), { email }, { merge: true }),

  cancelTempReserve: (id: string) => setDoc(doc(reserveDays, id), { email: '' }, { merge: true }),

  saveOrder: async (order: any) => {
    const docRef = doc(orders)
    await setDoc(docRef, { ...order, timeStamp: serverTimestamp() }, { merge: true })
    if (order.reserveId) await setDoc(doc(reserveDays, order.reserveId), { orderId: docRef.id }, { merge: true })
    return docRef.id
  },

  deleteReserve: (reserveId: string, orderId: string) => {
    deleteDoc(doc(orders, orderId))
    return setDoc(doc(reserveDays, reserveId), { email: '', orderId: '' }, { merge: true })
  },
  delete: {
    event: async (targetEventId: string) => {
      await deleteDoc(doc(reserveDays, targetEventId));
    },
  },

  // --- カレンダーイベント関連 ---
save: {
  event: {
    getNewDocID: () => {
      const docRef = doc(reserveDays);
      return { id: docRef.id };
    },
    new: async (docRef: any, eventData: any) => {
      // 新規イベントデータを保存（ドキュメントIDは事前に取得したものを利用）
      console.log('firestore setDoc eventData:', eventData);
      await setDoc(doc(reserveDays, docRef.id), eventData, { merge: true });
    },
    edit: async (eventData: any) => {
      // 既存イベントの更新
      await setDoc(doc(reserveDays, eventData.id), eventData, { merge: true });
    },
  },
  // ここに予約完了用の関数を追加
  reserve: {
    /**
     * 予約完了時に、予約データ（reserveDaysV2 のドキュメント）を更新する関数
     * 例として、注文ID(orderId)などをセットする用途に利用します。
     * @param reserveId 予約対象のドキュメントID
     * @param reserveData 予約完了時に更新するデータ（任意）
     */
    reserveSave: async (reserveId: string, reserveData?: any) => {
      // ここでは、reserveData が指定されていればその内容で更新、なければ単に空のオブジェクトで merge 更新
      await setDoc(doc(reserveDays, reserveId), reserveData || {}, { merge: true });
    },
    tempReturn: async (reserveDateId: string) => {
      await setDoc(doc(reserveDays, reserveDateId), { email: '' }, { merge: true });
    },
  },
},
}

// --- Export default for convenience ---
export default { authService, firestoreService }
