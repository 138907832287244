// src/stores/authStore.ts
import { defineStore } from 'pinia';
import { authService, firestoreService } from '@/services/firebaseService';
import { useUserStore } from '@/stores/userStore';
import { useSettingsStore } from '@/stores/settingsStore';
import { useReservedPageStore } from '@/stores/reservedPageStore';
import { useDashboardCalendarStore } from '@/stores/dashboardCalendarStore';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    uid: '',
    name: '',
    email: '',
    loggedIn: false,
    loading: false,
    loginButton: false,
    resetPassword: false,
  }),
  getters: {
    isLogin: (state) => state.loggedIn,
  },
  actions: {
    async login(mail: string, pass: string) {
      this.loading = true;
      try {
        const cred = await authService.login(mail, pass);
        if (cred && cred.user) {
          console.log('ログイン')
          this.uid = cred.user.uid;
          this.loggedIn = true;

          // ログイン後に各種データを読み込む
          const userStore = useUserStore();
          const settingsStore = useSettingsStore();
          const reservedPageStore = useReservedPageStore();
          const dashboardCalendarStore = useDashboardCalendarStore();

          await userStore.loadReservePlans(cred.user.uid);
          await settingsStore.load();
          await reservedPageStore.loadReservations(cred.user.uid);
          dashboardCalendarStore.events = reservedPageStore.serverItems;
          dashboardCalendarStore.plans = reservedPageStore.plans;

          // さらに、ユーザー情報の更新が必要であれば firestoreService.getUserData を使ってもよい
          const userData = await firestoreService.getUserData(cred.user.uid);
          if (userData) {
            this.name = userData.name || '';
            this.email = userData.email || '';
          }
        }
      } catch (error) {
        console.error('authStore login error', error);
        alert('ログインに失敗しました');
      }
      this.loading = false;
      this.loginButton = false;
    },

    async logout(router: any) {
      try {
        await authService.logout();
        this.$reset();
        router.push('/Dashboard');
      } catch (e) {
        console.error('ログアウト失敗', e);
        alert('ログアウトに失敗しました');
      }
    },

    async resetPasswordAction() {
      try {
        await authService.resetPassword(this.email);
        alert('パスワードリセットメールを送信しました');
      } catch (error) {
        console.error('resetPassword error', error);
        alert('パスワードリセットに失敗しました');
      }
    },
  },
});
