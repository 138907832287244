<template>
  <v-container>
    <v-row>
      <v-col>
        <!-- FullCalendar コンポーネント -->
        <full-calendar :options="calendarOptions"></full-calendar>
        <v-card>
          <v-dialog v-model="calendarStore.showModal" max-width="500px">
            <v-card lg="6">
              <div v-if="calendarStore.selected">
                <v-card-title>{{ selectPlanTitle(calendarStore.selected.selectedPlanId) }}</v-card-title>
                <v-card-text>
                  開始時間：{{ formatDate(calendarStore.selected.start) }}<br>
                  終了時間：{{ formatDate(calendarStore.selected.end) }}
                  <div>
                    <v-text-field v-model="reserveUser.name" label="お名前"></v-text-field>
                    <v-text-field v-model="reserveUser.email" label="メールアドレス"></v-text-field>
                  </div>
                  <v-radio-group v-model="reserveUser.selectedPlan">
                      <!-- イベント内の plan 配列をループ -->
                      <div v-for="plan in calendarStore.selected.plan" :key="plan.id">
                        <!-- 仮に、選択中の plan と plan.id を比較している例 -->
                        <template v-if="calendarStore.selected.selectedPlanId === plan.id">
                          <v-radio 
                            :label="plan.price ? `料金：${plan.price}円` : '無料'" 
                            :value="plan">
                          </v-radio>
                          <v-textarea readonly v-model="plan.description" label="プラン詳細"></v-textarea>
                        </template>
                      </div>
                    </v-radio-group>

                </v-card-text>
              </div>
              <v-card-actions>
                <v-btn color="primary" @click="closeModal" :disabled="reserveUser.submit">
                  キャンセル
                </v-btn>
                <!-- 予約ボタンは直接 submitReservation を呼ばず、確認ダイアログを開く -->
                <v-btn @click="confirmReservationDialog = true"
                  :disabled="!reserveUser.name || !reserveUser.email || !reserveUser.selectedPlan || reserveUser.submit">
                  予約する
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          
          <!-- 追加の確認用ダイアログ -->
          <v-dialog v-model="confirmReservationDialog" max-width="400px">
            <v-card>
              <v-card-title class="text-h5">予約確認</v-card-title>
              <v-card-text>
                本当に予約を確定してよろしいですか？
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="confirmReservationDialog = false">
                  いいえ
                </v-btn>
                <v-btn color="blue darken-1" text @click="handleConfirmReservation">
                  はい
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, onMounted, computed,onUnmounted,watch } from 'vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import { useCalendarStore } from '@/stores/calendarStore'
import { useReservationStore } from '@/stores/reservationStore'
import { useAuthStore } from '@/stores/authStore'
import { useSettingsStore } from '@/stores/settingsStore'
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'

const calendarStore = useCalendarStore()
const reservationStore = useReservationStore()
const authStore = useAuthStore()
const settingsStore = useSettingsStore()

// 予約者情報はローカル管理（ここでは reserveUser に uid、名前、メール、選択プランなどを保持）
const reserveUser = ref({
  name: '',
  email: '',
  // selectedPlan はプランのIDまたはプランオブジェクトのうち必要な部分（ここでは ID を保持）
  selectedPlan: '',
  submit: false,
  uid: '',
  // reserveDate は予約日時文字列として後でセットする
  reserveDate: ''
})

// 予約確認ダイアログの表示状態
const confirmReservationDialog = ref(false)

const router = useRouter()
const route = useRoute()

const windowWidth = ref(window.innerWidth)
const updateWindowWidth = () => {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener('resize', updateWindowWidth)
})
onUnmounted(() => {
  window.removeEventListener('resize', updateWindowWidth)
})

const calendarOptions = computed(() => {
  let view = 'dayGridMonth'
if (windowWidth.value < 900) {
    view = 'listWeek' // 中程度の画面では週表示
  } else {
    view = 'dayGridMonth' // 大きい画面では月表示
  }
  const plans = calendarStore.plans;

  return {
    initialView: view,
    locale: 'ja-JP',
    plugins: [dayGridPlugin, interactionPlugin,listPlugin],
    events: calendarStore.events,
    eventClick: calendarStore.select,
    eventContent: (arg: any) => {
      // console.log('eventContent arg:', arg);
      const timeText = arg.timeText;
      const selectedPlanId = arg.event.extendedProps.selectedPlan;
      console.log('selectedPlanId:', selectedPlanId);
      const planObj = plans.find((p: any) => p.id === selectedPlanId);
      console.log('planObj:', planObj);
      const planName = planObj ? planObj.title : '';
      const color = arg.event.backgroundColor || arg.event.extendedProps.color || '#3788d8';
      const markerHtml = `<span style="
        background-color: ${color};
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 4px;
      "></span>`;
      const trimmedPlanName = planName.length > 8 ? planName.slice(0, 6) + '...' : planName;
      if(view === 'dayGridMonth'){
        const html = `<div>${markerHtml}<strong>${timeText}</strong> ${trimmedPlanName}</div>`;
        return { html };
      } else{
        const html = `<div>${markerHtml}<strong>${timeText}</strong> ${planName}</div>`;
        return { html };
      }
    },
  };
});



// 日付フォーマット関数
function formatDate(date: Date | string): string {
  return dayjs(date).format('MM月DD日 HH時mm分')
}

function selectPlanTitle(planId: string) {
  const plan = calendarStore.plans.find((p: any) => p.id === planId)
  return plan ? plan.title : ''
}

function closeModal() {
  calendarStore.showModal = false
}

/**
 * 予約確認ダイアログで「はい」が押された場合に呼ばれる
 */
async function handleConfirmReservation() {
  confirmReservationDialog.value = false
  await submitReservation()
}

/**
 * submitReservation のメイン処理
 * ・選択されたイベント（1件のみ）を reserveData として取得
 * ・reservationStore.tempSave() で仮予約を実行
 * ・確認用ページ（/reserve）へ遷移
 * ・設定データのチェック後、支払いが必要なら reservationStore.creditPayment() を実行
 *   ※支払い不要の場合は、Zoom ミーティング作成等の正式予約処理 submitSection2() を実行
 */
async function submitReservation() {
  reserveUser.value.submit = true
  let reserveData: any = null
  try {
    // if (calendarStore.selectedEventDetails.length > 1) {
    //   alert('複数の予約は出来ません')
    //   return
    // }
    reserveData = calendarStore.selected
    if (!reserveData || !reserveData.id) return

    // ① 仮予約（tempSave）
    const tempResult = await reservationStore.tempSave(reserveData.id)
    if (!tempResult) {
      alert('仮予約に失敗しました')
      return
    }

    // ② 予約確認用ページ（/reserve）へ遷移
    router.push('/reserve')

    // ③ 設定データの読み込み
    await settingsStore.load()
    if (!settingsStore.settingData.replyMailAddress) {
      alert('返信先メールアドレスが設定されていません')
      await reservationStore.cancel(reserveData.id)
      router.back()
      calendarStore.showModal = false
      return
    }

    // ④ 支払い金額の算出
    let paymentPrice = 0
    if (reserveData.plan && reserveData.plan.length > 0) {
      const selectedPlan = reserveData.plan.find((p: any) => p.id === reserveData.selectedPlanId)
      paymentPrice = selectedPlan ? parseInt(selectedPlan.price) : 0
    } else {
      paymentPrice = reserveData.price || 0
    }
    console.log('paymentPrice:', paymentPrice)

    // ⑤ 決済処理が必要な場合
    if ((settingsStore.settingData.stripe.use && paymentPrice !== 0) ||
        (settingsStore.settingData.stores.use && paymentPrice !== 0)) {
      if (settingsStore.settingData.stripe.use && !settingsStore.settingData.stripe.connectedAccountId) {
        alert('Stripeのアカウントが設定されていません。設定ページからStripeのアカウント設定をお願いします。')
        await reservationStore.cancel(reserveData.id)
        router.back()
        calendarStore.showModal = false
        return
      }
      const creditResult = await reservationStore.creditPayment(
        reserveData,
        reserveUser.value,
        settingsStore.settingData
      )
      if (!creditResult) {
        alert('クレジット決済に失敗しました')
        await reservationStore.cancel(reserveData.id)
        router.back()
        calendarStore.showModal = false
        return
      }
      // ※決済処理の場合、window.location.href で遷移するため、以降の処理は実行されない可能性がある
    } else {
      // ⑥ 支払い不要の場合：正式予約処理の続行
      localStorage.setItem('reserveData', JSON.stringify(reserveData))
      localStorage.setItem('reserveUser', JSON.stringify(reserveUser.value))
      localStorage.setItem('settingData', JSON.stringify(settingsStore.settingData))
      router.push(`/${authStore.uid}/reserve`)
    }
  } catch (e: any) {
    console.error('Reservation submit error', e)
    alert('予約処理に失敗しました')
    if (reserveData?.id) {
      await reservationStore.cancel(reserveData.id)
    }
  } finally {
    reserveUser.value.submit = false
  }
}

/**
 * submitSection2：Zoom ミーティング作成、正式予約登録、メール送信、予約完了処理
 */
// async function submitSection2(reserveData: any) {
//   try {
//     let zoomResult = null
//     if (settingsStore.settingData.zoom && !reserveData.paymentOnly) {
//       zoomResult = await reservationStore.createZoomMeeting(reserveData, reserveUser.value)
//       if (!zoomResult) {
//         alert('Zoomミーティングの作成に失敗しました')
//         await reservationStore.cancel(reserveData.id)
//         router.back()
//         calendarStore.showModal = false
//         return
//       }
//       console.log('zoomResult:', zoomResult)
//     }
//     await reservationStore.addReserve(zoomResult, reserveData)
//     await reservationStore.reserveComplete(reserveData, settingsStore.settingData, reserveUser.value)
//     await reservationStore.sendEmail(zoomResult, reserveData, settingsStore.settingData, reserveUser.value)
//     router.push('/ReserveComplete')
//   } catch (e: any) {
//     await reservationStore.eject(router, reserveData, e)
//   }
// }

onMounted(async () => {
  // URL パラメータから uid を取得
  const uid = Array.isArray(route.params.uid) ? route.params.uid[0] : route.params.uid
  reserveUser.value.uid = uid
  authStore.uid = uid;

  const temp = localStorage.getItem('reserveData')
  if (temp) {
    const tempReserveData = JSON.parse(temp)
    await reservationStore.cancel(tempReserveData.id)
    localStorage.clear()
  } else {
    await calendarStore.load(uid)
    console.log('calendarStore.events',calendarStore.events)
  }

})
</script>


<style>
@media screen and (max-width: 600px){
  .fc-view-harness {
    height: 426px !important;
  }
}
</style>
