import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

import { onMounted, onUnmounted, ref, computed } from 'vue'
import FullCalendar from '@fullcalendar/vue3'
import { useDashboardCalendarStore } from '@/stores/dashboardCalendarStore'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'


export default /*@__PURE__*/_defineComponent({
  __name: 'AddReserveCalendar',
  setup(__props) {

const router = useRouter()
const dashboardCalendar = useDashboardCalendarStore()
const menuRef = ref<HTMLElement | null>(null)

// 「変更する」ボタンの disable フラグ
const disableEditButton = computed(() => {
  // 選択されたイベントの中に googleCalendar が true のものがあれば disable する
  return dashboardCalendar.selectedEventDetails.some(item => item.googleCalendar)
})

// computed プロパティで FullCalendar のオプションを構築
const calendarOptions = computed(() => ({
  initialView: 'dayGridMonth',
  locale: dashboardCalendar.locale,
  plugins: dashboardCalendar.plugins,
  events: dashboardCalendar.events,
  editable: true, // ドラッグ＆ドロップが有効になります
  eventClick: dashboardCalendar.eventClick,
  dateClick: dashboardCalendar.dateClick,
  eventDidMount: dashboardCalendar.eventDidMount,
  dayCellDidMount: dashboardCalendar.dayCellDidMount,
  eventContent: (arg: any) => {
    console.log(arg);
    const timeText = arg.timeText;
    const selectedPlanId = arg.event.extendedProps.selectedPlan;
    const plans = dashboardCalendar.plans;
    const planObj = plans.find((p: any) => p.id === selectedPlanId);
    const planName = planObj ? planObj.title : '';
    

    // イベント全体の色として、もし設定されていれば backgroundColor または extendedProps.color を使用
    const color = arg.event.backgroundColor || arg.event.extendedProps.color || '#3788d8';

    // 小さな丸いカラーマーカーを作成
    const markerHtml = `<span style="
      background-color: ${color};
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 4px;
    "></span>`;

    const trimmedPlanName = planName.length > 8 ? planName.slice(0, 8) + '...' : planName;
    const html = `<div>${markerHtml}<strong>${timeText}</strong> ${trimmedPlanName}</div>`;
    return { html };
  },
  // その他必要なオプション
}));

const handleClickOutside = (event: any) => {
  if (menuRef.value && !menuRef.value.contains(event.target)) {
    dashboardCalendar.menu = false
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})

const saveEventEdit = () => {
  dashboardCalendar.saveEventEdit()
}

const saveEventNew = () => {
  dashboardCalendar.saveEventNew()
}

const menuAction = (action: string, date: any) => {
  dashboardCalendar.menuAction(action, date)
}

const getGoogleCalendarEvents = () => {
  dashboardCalendar.getGoogleCalendarEvents()
}

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                onClick: getGoogleCalendarEvents,
                disabled: _unref(dashboardCalendar).googleCalendarEventsFetched
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Google カレンダーから取得 ")
                ])),
                _: 1
              }, 8, ["disabled"]),
              _createVNode(_unref(FullCalendar), { options: calendarOptions.value }, null, 8, ["options"]),
              _createVNode(_component_v_dialog, {
                modelValue: _unref(dashboardCalendar).showModal,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(dashboardCalendar).showModal) = $event)),
                "max-width": "500px"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, { lg: "6" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dashboardCalendar).selectedEventDetails, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: item.id
                        }, [
                          _createVNode(_component_v_card_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.startStr) + " " + _toDisplayString(item.title), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_form, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, { cols: "6" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            modelValue: item.month,
                                            "onUpdate:modelValue": ($event: any) => ((item.month) = $event),
                                            maxlength: "2",
                                            suffix: "月",
                                            readonly: disableEditButton.value
                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "readonly"])
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_v_col, { cols: "6" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            modelValue: item.day,
                                            "onUpdate:modelValue": ($event: any) => ((item.day) = $event),
                                            maxlength: "2",
                                            suffix: "日",
                                            readonly: disableEditButton.value
                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "readonly"])
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_v_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, { cols: "6" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            readonly: disableEditButton.value,
                                            type: "number",
                                            modelValue: item.startHour,
                                            "onUpdate:modelValue": ($event: any) => ((item.startHour) = $event),
                                            label: "開始時間",
                                            maxlength: "2",
                                            suffix: "時",
                                            required: ""
                                          }, null, 8, ["readonly", "modelValue", "onUpdate:modelValue"])
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_v_col, { cols: "6" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            readonly: disableEditButton.value,
                                            type: "number",
                                            modelValue: item.startMinute,
                                            "onUpdate:modelValue": ($event: any) => ((item.startMinute) = $event),
                                            label: "開始分",
                                            maxlength: "2",
                                            suffix: "分",
                                            required: ""
                                          }, null, 8, ["readonly", "modelValue", "onUpdate:modelValue"])
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_v_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, { cols: "6" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            readonly: disableEditButton.value,
                                            type: "number",
                                            modelValue: item.endHour,
                                            "onUpdate:modelValue": ($event: any) => ((item.endHour) = $event),
                                            label: "終了時間",
                                            maxlength: "2",
                                            suffix: "時",
                                            required: ""
                                          }, null, 8, ["readonly", "modelValue", "onUpdate:modelValue"])
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_v_col, { cols: "6" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            readonly: disableEditButton.value,
                                            type: "number",
                                            modelValue: item.endMinute,
                                            "onUpdate:modelValue": ($event: any) => ((item.endMinute) = $event),
                                            label: "終了分",
                                            maxlength: "2",
                                            suffix: "分",
                                            required: ""
                                          }, null, 8, ["readonly", "modelValue", "onUpdate:modelValue"])
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024),
                              (!item.googleCalendar)
                                ? (_openBlock(), _createBlock(_component_v_radio_group, {
                                    key: 0,
                                    modelValue: item.selectedPlan,
                                    "onUpdate:modelValue": ($event: any) => ((item.selectedPlan) = $event)
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.plan, (plan) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: plan.id
                                        }, [
                                          _createVNode(_component_v_radio, {
                                            label: `${plan.title} - ${plan.price}円`,
                                            value: plan.id
                                          }, null, 8, ["label", "value"])
                                        ]))
                                      }), 128))
                                    ]),
                                    _: 2
                                  }, 1032, ["modelValue", "onUpdate:modelValue"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128)),
                      _createVNode(_component_v_card_actions, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            color: "primary",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(dashboardCalendar).showModal = false))
                          }, {
                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                              _createTextVNode("キャンセル")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_btn, {
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (saveEventEdit())),
                            disabled: disableEditButton.value
                          }, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                              _createTextVNode(" 変更する ")
                            ])),
                            _: 1
                          }, 8, ["disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_v_dialog, {
                modelValue: _unref(dashboardCalendar).addReserveModal,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(dashboardCalendar).addReserveModal) = $event)),
                "max-width": "500px"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, { lg: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode("新規追加")
                        ])),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dashboardCalendar).selectedEventDetails, (item) => {
                        return (_openBlock(), _createBlock(_component_v_card_text, {
                          key: item.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_form, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { cols: "6" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          modelValue: item.month,
                                          "onUpdate:modelValue": ($event: any) => ((item.month) = $event),
                                          maxlength: "2",
                                          suffix: "月",
                                          readonly: disableEditButton.value
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "readonly"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_col, { cols: "6" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          modelValue: item.day,
                                          "onUpdate:modelValue": ($event: any) => ((item.day) = $event),
                                          maxlength: "2",
                                          suffix: "日",
                                          readonly: disableEditButton.value
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "readonly"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { cols: "6" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          readonly: disableEditButton.value,
                                          modelValue: item.startHour,
                                          "onUpdate:modelValue": ($event: any) => ((item.startHour) = $event),
                                          label: "開始時間",
                                          maxlength: "2",
                                          suffix: "時",
                                          required: ""
                                        }, null, 8, ["readonly", "modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_col, { cols: "6" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          readonly: disableEditButton.value,
                                          modelValue: item.startMinute,
                                          "onUpdate:modelValue": ($event: any) => ((item.startMinute) = $event),
                                          label: "開始分",
                                          maxlength: "2",
                                          suffix: "分",
                                          required: ""
                                        }, null, 8, ["readonly", "modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { cols: "6" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          readonly: disableEditButton.value,
                                          modelValue: item.endHour,
                                          "onUpdate:modelValue": ($event: any) => ((item.endHour) = $event),
                                          label: "終了時間",
                                          maxlength: "2",
                                          suffix: "時",
                                          required: ""
                                        }, null, 8, ["readonly", "modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_col, { cols: "6" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          readonly: disableEditButton.value,
                                          modelValue: item.endMinute,
                                          "onUpdate:modelValue": ($event: any) => ((item.endMinute) = $event),
                                          label: "終了分",
                                          maxlength: "2",
                                          suffix: "分",
                                          required: ""
                                        }, null, 8, ["readonly", "modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                (_unref(dashboardCalendar).validateTimeOverlap(item) !== true && item.startHour !== '' && item.startMinute !== '' && item.endHour !== '' && item.endMinute !== '')
                                  ? (_openBlock(), _createBlock(_component_v_alert, {
                                      key: 0,
                                      type: "error",
                                      dense: "",
                                      text: ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(dashboardCalendar).validateTimeOverlap(item)), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_v_radio_group, {
                                  modelValue: item.selectedPlan,
                                  "onUpdate:modelValue": ($event: any) => ((item.selectedPlan) = $event),
                                  required: ""
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.plan, (plan) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: plan.id
                                      }, [
                                        _createVNode(_component_v_radio, {
                                          label: `${plan.title} - ${plan.price}円`,
                                          value: plan.id
                                        }, null, 8, ["label", "value"])
                                      ]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1032, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      _createVNode(_component_v_card_actions, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            color: "primary",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(dashboardCalendar).addReserveModal = false)),
                            disabled: _unref(dashboardCalendar).adding
                          }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode(" キャンセル ")
                            ])),
                            _: 1
                          }, 8, ["disabled"]),
                          _createVNode(_component_v_btn, {
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (saveEventNew())),
                            disabled: _unref(dashboardCalendar).adding
                          }, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createTextVNode(" 追加する ")
                            ])),
                            _: 1
                          }, 8, ["disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"]),
              (_unref(dashboardCalendar).menu)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "context-menu",
                    style: _normalizeStyle({ top: _unref(dashboardCalendar).menuY + 'px', left: _unref(dashboardCalendar).menuX + 'px' }),
                    ref_key: "menuRef",
                    ref: menuRef
                  }, [
                    _createVNode(_component_v_list, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dashboardCalendar).menuItems, (item, index) => {
                          return (_openBlock(), _createBlock(_component_v_list_item, {
                            key: index,
                            onClick: ($event: any) => (menuAction(item.action, item.date))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.text), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ], 4))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})